import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import logger from '../../utils/Logging';
import {
    StyledCloudUploadIcon,
    StyledDropZoneContainer,
    StyledDropzoneContent,
    StyledDropzoneErrorContent,
    StyledDropzoneUploadContainer,
    StyledSectionContainer,
} from './FileDropZoneStyledComponents';
import type FileToUpload from './FileToUpload';
import { ParseFolder } from './ParseFolder';

interface FileDropZoneProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [field: string]: any;
    accept?: string;
    maxSize?: number;

    /* When multiple is true allow multiple folders or multiple files
  Will be true when folders and files are both true */
    multiple?: boolean;

    /* Setting both folders and files to true allows drag and drop of folders with file siblings
   Allow folder(s); defaults to false */
    folders?: boolean;

    // Allow file(s); defaults to true
    files?: boolean;

    // Disabled form for event like submitting
    disabledDropZone?: boolean;

    // Whether the field is required
    required?: boolean;

    'aria-describedby'?: string;

    'aria-label'?: string;
}

export const FileDropZone: React.FC<FileDropZoneProps> = (
    {
        accept,
        maxSize,
        multiple,
        folders,
        files,
        disabledDropZone,
        required,
        id,
        ...props
    }) => {

    const {
        form: {
            setFieldValue, errors, touched, setFieldTouched, submitCount,
        },
    } = props;
    const { field: { name } } = props;

    const { t } = useTranslation();
    const defaultMaxSize = 1e11;

    const maxSizeAllowed = maxSize ? maxSize : defaultMaxSize;
    const multipleAllowed = multiple;
    const takeFiles = files === undefined || files;
    const takeFolders = folders === undefined ? false : folders;
    const maxNameLength = 20;

    const [ filesToUpload, setFilesToUpload ] = React.useState<FileToUpload[]>([]);
    const [ numItems, setNumItems ] = React.useState(0);
    const drop = React.useRef(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = React.createRef<any>();
    const error = (errors && errors[name] && (touched && touched[name] === true) || submitCount > 0) ? errors[name] : '';

    const {
        isDragActive, getRootProps, getInputProps, acceptedFiles, rejectedFiles, rootRef, inputRef,
    } = useDropzone({
        onDrop: (incomingFiles: any[]) => {
            if (takeFiles && !takeFolders) {
                setFilesToUpload(incomingFiles.map(file => ({
                    path: file.name,
                    file,
                } as FileToUpload)));
            }
        },

        onFileDialogCancel: () => {
            setFieldTouched(name, true);
        },

        disabled: disabledDropZone,
        accept,
        minSize: 0,
        maxSize: maxSizeAllowed,
        multiple: multipleAllowed || takeFolders,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSizeAllowed;

    /*
        Workaround the fact that react-dropzone makes the file input non-interactive and makes the container
        interactive by adding tabIndex to it. This makes it impossible for screen readers like NVDA to properly
        detect the "required" attrinute. To make the component accessible, do the following:

        - Make the hidden input interactive via the keyboard by assigning it a tabindex of 0
        - Make the dropzone root container non-interactive to allow keyboard navigation to only focus the input

        Tested with NVDA and VoiceOver for Mac OS
    */
    React.useEffect(() => {
        if (rootRef) {
            rootRef.current?.removeAttribute('tabindex');
        }
    }, [ rootRef ]);

    React.useEffect(() => {
        if (inputRef) {
            inputRef.current?.setAttribute('tabindex', '0');
        }
    }, [ inputRef ]);

    React.useEffect(() => {
        if (takeFolders) {
            ref.current.directory = true;
            ref.current.webkitdirectory = true;
        }
    }, [ acceptedFiles, ref ]);

    React.useEffect(() => {
        if (filesToUpload.length > 0) {
            setFieldValue(name, filesToUpload);
        }
    }, [ filesToUpload ]);

    return (
        <StyledSectionContainer>
            <div
                id="dropzone"
                {...getRootProps(takeFolders ? {
                    onDrop: (event: any): void => {
                        drop.current = true;
                        setNumItems(0);
                        const entries = [];
                        const dtItems = event.dataTransfer.items;
                        for (let i = 0; i < dtItems.length; i++) {
                            const entry = dtItems[i].webkitGetAsEntry();
                            if ((!takeFiles && entry?.isDirectory) || takeFiles) {
                                entries.push(entry);
                                if (!multiple) {
                                    break;
                                }
                            }
                        }

                        // Check if there were any valid entries
                        if (!entries.length) {
                            setFieldTouched(name, true);
                        } else {
                            ParseFolder(entries).then(files => {
                                setFilesToUpload(files);
                                setNumItems(entries.length);
                                return true;
                            })
                                .catch((error: Error) => {
                                    logger.error({
                                        identifier: 'FileDropZone',
                                        message: 'Error parsing folders',
                                        error,
                                    });
                                });
                        }
                    },
                    onClick: (): void => ref?.current?.click(),
                } : {
                    onDrop: (event: any): void => {
                        const dtItems = event.dataTransfer.items;
                        for (let i = 0; i < dtItems.length; i++) {
                            const entry = dtItems[i].webkitGetAsEntry();
                            if (entry?.isDirectory) {
                                break;
                            }
                        }
                    },
                })}
            >
                {takeFolders ?
                    <input
                        {...getInputProps()}
                        ref={ref}
                        id={name}
                        className="dropzone-input"
                        name={name}
                        required={required}
                        aria-describedby={error ? `${id}-dropzone-error-label` : (props['aria-describedby'] ?? '')}
                        aria-label={props['aria-label'] ?? ''}
                        disabled={disabledDropZone}
                        onChange={(event): void => {
                            const entries: FileToUpload[] = [];
                            const files = event.target.files;
                            if (files && files.length > 0) {
                                for (let i = 0; i < files.length; i++) {
                                    const file = files[i];
                                    entries.push({
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        path: (file as any).webkitRelativePath,
                                        file,
                                    });
                                }
                                setFilesToUpload(entries);
                            }
                        }}
                    />
                    : <input
                        {...getInputProps()}
                        id={name}
                        className="dropzone-input"
                        name={name}
                        required={required}
                        disabled={disabledDropZone}
                        aria-describedby={error ? `${id}-dropzone-error-label` : (props['aria-describedby'] ?? '')}
                        aria-label={props['aria-label'] ?? ''}
                    />}

                <StyledDropZoneContainer
                    error={error}
                    disabled={disabledDropZone}>
                    {!error && !isDragActive && rejectedFiles.length === 0 &&
                        <StyledDropzoneUploadContainer>
                            <StyledCloudUploadIcon />
                            {takeFolders ?
                                <StyledDropzoneContent
                                    isEmpty={numItems === 0}>
                                    {filesToUpload.length === 0
                                        ? t('fileDropZone_initialState')
                                        : (
                                            filesToUpload[0].path.split('/')[0].length < maxNameLength
                                                ? filesToUpload[0].path.split('/')[0]
                                                : (filesToUpload[0].path.split('/')[0].substring(0, maxNameLength) + '...') + (numItems > 1
                                                    ? ` and ${numItems - 1} more`
                                                    : ''
                                                )
                                        )}
                                </StyledDropzoneContent> :
                                <StyledDropzoneContent
                                    isEmpty={(acceptedFiles && acceptedFiles.length === 0)}>
                                    {acceptedFiles && (acceptedFiles.length === 0
                                        ? t('fileDropZone_initialState')
                                        : (acceptedFiles[0].name.length < maxNameLength
                                            ? acceptedFiles[0].name
                                            : (acceptedFiles[0].name.substring(0, maxNameLength) + '...')) + (acceptedFiles.length > 1
                                            ? ` and ${acceptedFiles.length - 1} more`
                                            : ''
                                        )
                                    )}
                                </StyledDropzoneContent>}
                        </StyledDropzoneUploadContainer>}
                    {
                        isDragActive && rejectedFiles.length === 0 &&
                        <StyledDropzoneUploadContainer>
                            <StyledCloudUploadIcon />
                            <StyledDropzoneContent
                                isEmpty>
                                {t('fileDropZone_hoverState')}
                            </StyledDropzoneContent>
                        </StyledDropzoneUploadContainer>
                    }
                    {
                        (!isDragActive && (error || isFileTooLarge || rejectedFiles.length > 0)) &&
                        <StyledDropzoneUploadContainer>
                            <StyledCloudUploadIcon />
                            <StyledDropzoneContent
                                isEmpty>
                                {t('fileDropZone_errorState')}
                            </StyledDropzoneContent>
                        </StyledDropzoneUploadContainer>
                    }
                </StyledDropZoneContainer>
            </div>

            { /* Display error */}
            {
                error &&
                <StyledDropzoneErrorContent id={`${id}-dropzone-error-label`}>
                    {errors[name]}
                </StyledDropzoneErrorContent>
            }
        </StyledSectionContainer >
    );
};
