import { styled } from '@mui/material';
import type {
    AppProvisionDto,
    DatasetDto,
} from '@uipath/aifabric';
import type { CancelTokenSource } from 'axios';
import {
    Field,
    Formik,
} from 'formik';
import { Switch } from 'formik-mui';
import React, {
    useContext,
    useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';
import * as Yup from 'yup';

import { createApp } from '../../../api/client/appManagerClient';
import { createDataset } from '../../../api/client/datasetManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import TooltipContainer from '../../../components/AccessibleTooltip/TooltipContainer';
import { CreateDataset } from '../../../components/dataset/CreateDataset';
import { FormAutoCompleteServerSide } from '../../../components/FormAutoComplete';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import { Validation as val } from '../../../constants/validation';
import type FeatureFlagManager from '../../../feature-flag/FeatureFlagManager';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import { extractErrorMessage } from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';
import { loadDataset } from '../LoadDataset';

const Header = styled('h2')(({ theme }) => ({ color: theme.palette.semantic.colorForeground }));

interface DataLabelingSessionCreateProps {
    isOnPrem: boolean;
    featureManager: FeatureFlagManager;
    isLabelBoxApp?: boolean;
}

const DataLabelingSessionCreateWrapper: React.FC<DataLabelingSessionCreateProps> = ({
    isOnPrem, featureManager,
}) => {
    const locationState: any = useLocation()?.state;
    const isLabelBoxApp = (locationState?.data?.appType === 'LABEL_BOX');

    return (
        <DataLabelingSessionCreate
            isOnPrem={isOnPrem}
            featureManager={featureManager}
            isLabelBoxApp={isLabelBoxApp} />
    );
};

export const DataLabelingSessionCreate: React.FC<DataLabelingSessionCreateProps> = ({
    isOnPrem,
    featureManager,
    isLabelBoxApp,
}) => {
    const { datasetId } = useParams<{ datasetId: string }>();
    const history = useHistory();
    const locationState: any = useLocation()?.state;

    const { t } = useTranslation();
    const feedback = useFeedback();

    const [ datasets, setDatasets ] = React.useState<DatasetDto[]>([]);
    const [ loadingState, setLoadingState ] = React.useState(true);
    const [ isDatasetLoadingInProgress, setIsDatasetLoadingInProgress ] = React.useState(false);

    const [ cancelToken, setCancelToken ] = React.useState<CancelTokenSource | null>(null);

    const { state } = useContext(ProjectsContext);
    const currentProject = state.currentProject;

    const loading = (datasets.length === 0 ? true : false) && (loadingState === true ? true : false);

    const saasLabellingAppFeatureFlag = 'saas-labelling-app';

    const setDatasetsInternal: Function = (data: DatasetDto[]) => {
        if (!isLabelBoxApp) {
            setDatasets(data);
        } else {
            setDatasets(data.filter(dataset => !dataset.encrypted));
        }
    };

    useEffect(() => {
        if (loading === true && isDatasetLoadingInProgress === false) {
            setLoadingState(true);
            loadDataset({
                projectId: currentProject?.id || '',
                cancelTokenCallback: setCancelToken,
                loadingInProgress: setIsDatasetLoadingInProgress,
                thenCallback: setDatasetsInternal,
                catchCallback: handleDatasetLoadingError,
                finalCallback: handleFinalDatasetLoadingState,
            });
            return function cleanup(): void {
                loadDataset.cancel();
                if (cancelToken) {
                    cancelToken.cancel();
                }
            };
        }
    });

    const handleDatasetLoadingError = (error: any) => {
        logger.error({
            identifier: 'DataLabeling Session Create',
            message: 'Error getting datasets',
            error,
        });
        feedback.enqueueError(extractErrorMessage(error, t('feedback_datasets_loading_error')));
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleFinalDatasetLoadingState = () => {
        setLoadingState(false);
        setIsDatasetLoadingInProgress(false);
    };

    return (
        <div>
            <Formik
                initialValues={{
                    appName: '',
                    exportDataset: '',
                    createNewDataset: false,
                    datasetName: '',
                    datasetDescription: '',
                    publicDS: false,
                    encryptedDS: false,
                }}
                onSubmit={async (values): Promise<void> => {
                    let responseDataset: DatasetDto | undefined;

                    try {
                        if (values.createNewDataset) {
                            responseDataset = await createDataset({
                                name: values.datasetName,
                                description: values.datasetDescription,
                                projectId: currentProject?.id,
                                public: values.publicDS,
                                encrypted: values.encryptedDS,
                            }).then((dataset) => {
                                feedback.enqueueSuccess(t('feedback_create_success_dataset'));
                                return dataset;
                            });
                        }
                    } catch (error) {
                        feedback.enqueueError(extractErrorMessage(error, t('COMMON_UNHANDLED_EXCEPTION'), {
                            10006: {
                                0: 'Dataset',
                                1: values.datasetName || '',
                            },
                            10007: {
                                0: 'Dataset',
                                1: values.datasetName || '',
                            },
                            60101: {},
                            10602: {
                                1: 'Dataset',
                                0: datasetId,
                            },
                            60104: { 0: datasetId },
                            60102: { 0: datasetId },
                        }));
                        return;
                    }

                    const exportedDataset: DatasetDto = responseDataset || datasets.find(dataset => dataset.id === values.exportDataset) as DatasetDto;
                    try {

                        const requestPayload: AppProvisionDto = {
                            projectId: currentProject?.id as string,
                            appPayload: {
                                exportedDatasetId: exportedDataset?.id as string,
                                name: values.appName,
                                type: 'DATA_MANAGER',
                            },
                        };

                        if (featureManager && featureManager.isEnabled(saasLabellingAppFeatureFlag)) {
                            requestPayload.appPayload.type = 'DATA_MANAGER_SAAS';
                        }

                        // override if this is labeling studio app
                        if (isLabelBoxApp) {
                            requestPayload.appPayload.type = 'LABEL_BOX';
                            requestPayload.appPayload.labellingOobTemplateId = locationState?.data?.oobTemplateId;
                        }

                        const newSessionData = await createApp(requestPayload);

                        feedback.enqueueSuccess(t('feedback_create_datalabeling_success'));
                        /* Go back to datalabeling list page */
                        if (isLabelBoxApp) {
                            history.push(generatePath(RoutePath.LABELING_SESSION_DASHBOARD, { projectName: currentProject?.name }), {
                                appId: newSessionData?.id,
                                projectId: newSessionData?.projectId,
                                datasetId: newSessionData?.exportedDatasetId,
                                configure: true,
                            });
                        } else {
                            history.push(generatePath(RoutePath.DATALABELING, { projectName: currentProject?.name }));
                        }

                    } catch (error) {
                        feedback.enqueueError(extractErrorMessage(error, t('feedback_create_datalabeling_error'), {
                            70101: { 0: values.appName || '' },
                            70102: { 0: values.appName || '' },
                            70128: { 0: exportedDataset?.name || '' },
                        }));
                    }
                }}
                validationSchema={Yup.object().shape({
                    appName: Yup.string()
                        .required(t('dl_session_create_name_required'))
                        .matches(val.appNameValidation, t('dl_session_create_name_validation_error'))
                        .max(val.appNameLengthMax,
                            t('dl_session_create_name_max_length', { max: val.appNameLengthMax })),
                    exportDataset: Yup.string().when('createNewDataset', {
                        is: true,
                        then: () => Yup.string(),
                        otherwise: () => Yup.string().required(t('dl_exported_dataset_required')),
                    }),
                    createNewDataset: Yup.boolean(),
                    datasetName: Yup.string().when('createNewDataset', {
                        is: true,
                        then: () => Yup.string()
                            .required(t('dataset_create_dsName_req'))
                            .matches(val.datasetCreateNameValidation, t('dataset_name_validation_error'))
                            .max(val.datasetCreateDatasetNameMax,
                                t('dataset_create_dsName_max', { max: val.datasetCreateDatasetNameMax })),
                    }),
                    datasetDescription: Yup.string().when('createNewDataset', {
                        is: true,
                        then: () => Yup.string()
                            .max(val.datasetCreateDescriptionMax,
                                t('dataset_create_dsDesc_max', { max: val.mlpackageCreateDescriptionMax })),
                    }),
                })}
            >
                {
                    (props): React.ReactElement => {
                        const {
                            dirty,
                            setFieldValue,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            values,
                            errors,
                        } = props;

                        const handleSelection = (itemChanged: boolean, fieldName: string, fieldValue: string): void => {
                            setFieldValue(fieldName, fieldValue);
                        };

                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                submitForm={submitForm}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                    />
                                }
                            >
                                <FormikErrorLabels errors={errors} />
                                <Header
                                    aria-label={t('a11y_page_section_title')}
                                    id='page-title'
                                    tabIndex={0}>
                                    {t('dl_session_creation_header')}
                                </Header>
                                <Label
                                    value={t('common_name_label')}
                                    required
                                    aria-label={`${t('common_name_label')} label`} />

                                <Field
                                    component={AccessibleFormikInput}
                                    className="textFeild min-width-override"
                                    requiredField
                                    isValid={!errors['appName']}
                                    type="text"
                                    name="appName"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder={t('dl_session_create_name_placeholder')}
                                    aria-label={`${t('dl_session_create_name_placeholder')} text field`}
                                    autoComplete="off"
                                    disabled={isSubmitting}
                                    data-testid="appName"
                                />
                                <TooltipContainer
                                    title={t('tooltip_data_labelling_choose_dataset')}
                                    customStyle={{ marginTop: '20px' }}>
                                    <Label
                                        id="dataset-name-label"
                                        value={`${t('dl_session_create_export_dataset_label')}`}
                                        required={!values.createNewDataset}
                                        aria-label={`${t('dl_session_create_export_dataset_label')} label`} />
                                </TooltipContainer>
                                <Field
                                    name="exportDataset"
                                    options={datasets}
                                    component={FormAutoCompleteServerSide}
                                    requiredField={!values.createNewDataset}
                                    loading={loading}
                                    type="select"
                                    labelKey="name"
                                    className="min-width-override"
                                    valueField="id"
                                    handleSelection={handleSelection}
                                    loadingText={t('auto_complete_loading_text')}
                                    noOptionsText={t('auto_complete_no_content_found')}
                                    placeholder={t('dl_session_create_export_dataset_placeholder')}
                                    aria-describedby="dataset-name-label"
                                    disabled={isSubmitting || values.createNewDataset}
                                    data-testid="exportDataset"
                                    isInvalid={!!errors.exportDataset}
                                />

                                <div className="createNewDatasetDiv">
                                    <Label value={t('dataset_create_title')} />
                                    <div aria-label={`${t('dataset_create_title')} switch ${values.createNewDataset ? `checked` : `unchecked`}`}>
                                        <Field
                                            name="createNewDataset"
                                            component={Switch}
                                            color="secondary"
                                            type="checkbox"
                                            disabled={isSubmitting}
                                            inputProps={{ 'aria-label': t('a11y_create_new_dataset_switch') }}
                                            data-testid="createNewDataset"
                                        />
                                    </div>
                                </div>
                                {
                                    values.createNewDataset ?
                                        (
                                            <CreateDataset
                                                isPublicDS={values.publicDS}
                                                isEncryptedDS={values.encryptedDS}
                                                disabled={isSubmitting}
                                                isOnPrem={isOnPrem}
                                                featureManager={featureManager}
                                                isLabelBoxApp={isLabelBoxApp ?? false}
                                            />
                                        ) : null
                                }
                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
    featureManager: state.featureManagement.featureManager,
}))(DataLabelingSessionCreateWrapper);
