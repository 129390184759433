import type {
    MLPackageCloneDto,
    MLPackageDto,
    MLPackageMetaDataDto,
    MLPackageVersionDto,
    ProjectDto,
} from '@uipath/aifabric';
import {
    Field,
    Formik,
} from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';
import * as Yup from 'yup';

import {
    getAllMlPackages,
    getPublicPackageById,
    getPublicPackages,
    getPublicProjects,
    uploadAndCreateImportedPackage,
} from '../../api/client/pkgManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import { FileDropZone } from '../../components/fileDropzone/FileDropZone';
import type FileToUpload from '../../components/fileDropzone/FileToUpload';
import FormButtonGroup from '../../components/FormButtonGroup';
import FormikErrorLabels from '../../components/FormikErrorLabels';
import FormLayout from '../../components/FormLayout';
import Label from '../../components/Label';
import { RoutePath } from '../../route/routeMap';
import {
    CustomError,
    extractErrorFields,
    extractErrorMessage,
} from '../../utils/CommonUtils';

interface ImportPackageProps {
    currentProject?: ProjectDto;
    importPackageType?:
    | 'ML_PACKAGE_CLONE'
    | 'ML_PACKAGE_VERSION_CLONE'
    | 'ML_PACKAGE_IMPORT'
    | 'ML_PACKAGE_VERSION_IMPORT'
    | 'ML_PACKAGE_UPLOAD'
    | 'ML_PACKAGE_VERSION_UPLOAD'
    | 'ML_PACKAGE_OOB_UPLOAD';
    isOobImport?: boolean;
    azureStorageFQDNSuffix: string;
}

export const ImportMLPackage: React.FC<ImportPackageProps> = ({
    currentProject, importPackageType, isOobImport, azureStorageFQDNSuffix,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const feedback = useFeedback();
    const PLACEHOLDER_UUID = 'cefb6cba-89ec-4a64-b6a0-a3d9646eebdd';
    const VERSION_MISSING_MESSAGE = 'version missing';
    const ML_PACKAGE_LANGUAGE_MISSING_MESSAGE = 'ML package language missing';
    const ML_PACKAGE_INPUT_TYPE_MISSING_MESSAGE = 'ML package input type missing';
    const ML_PACKAGE_NAME_MISSING_MESSAGE = 'ML package name missing';

    const authToken = useSelector((state: any) => state.auth.authToken);

    /**
   * Parses fileContent and does some sanity checks. Checks performed are listed below.
   * Set projectId to UUID format to make sure spring validation passes while body is posted to api. projectId is anyway ignored in the backend.
   * Set all uri and imagePath properties to empty string as this can screw up backend logic.
   * Ensure package version is passed on the metadata json.
   * @param fileContent Json file content
   * @returns MLPackageMetaDataDto object
   */
    const getSanitizeMetadataJSON = (fileContent: string): MLPackageMetaDataDto => {
        const dto = JSON.parse(fileContent) as MLPackageMetaDataDto;
        dto.projectId = currentProject ? currentProject.id : PLACEHOLDER_UUID;
        dto.contentUri = dto.stagingUri = '';
        if (!dto.mlPackageLanguage) {
            throw new Error(ML_PACKAGE_LANGUAGE_MISSING_MESSAGE);
        } else if (!dto.inputType) {
            throw new Error(ML_PACKAGE_INPUT_TYPE_MISSING_MESSAGE);
        } else if ((isOobImport && !dto.name) || (!isOobImport && !JSON.parse(fileContent)['mlPackageName'])) {
            // name validations
            throw new Error(ML_PACKAGE_NAME_MISSING_MESSAGE);
        }

        if (isOobImport && (!dto.version)) {
            throw new Error(VERSION_MISSING_MESSAGE);
        }
        return dto;
    };

    const getErrorCode = (error: any): number => {
        let errorCode = 10003;
        if (error?.message) {
            if (error?.message === VERSION_MISSING_MESSAGE) {
                errorCode = 20102;
            } else if (error?.message === ML_PACKAGE_LANGUAGE_MISSING_MESSAGE) {
                errorCode = 103;
            } else if (error?.message === ML_PACKAGE_INPUT_TYPE_MISSING_MESSAGE) {
                errorCode = 104;
            } else if (error?.message === ML_PACKAGE_NAME_MISSING_MESSAGE) {
                errorCode = 105;
            }
        }
        return errorCode;
    };

    const routePage = (mlpackageId: string | undefined, mlpackageName: string | undefined,
        sourcePackageId: string | undefined, publicProject: ProjectDto | undefined, isPublicPackage: boolean): void => {

        if (isPublicPackage) {
            history.push({
                pathname: generatePath(RoutePath.MLPACKAGE_VERSION, {
                    projectName: currentProject?.name,
                    mlpackageId,
                    mlpackageName,
                }),
                state: {
                    data: {
                        sourcePackageId,
                        mlPackageOwnedByAccountId: publicProject?.accountId,
                        mlPackageOwnedByTenantId: publicProject?.tenantId,
                        mlPackageOwnedByProjectId: publicProject?.id,
                    },
                },
            });
        } else {
            history.push(generatePath(RoutePath.MLPACKAGE_VERSION, {
                projectName: currentProject?.name,
                mlpackageName,
                mlpackageId,
            }));
        }
    };

    const validatePackageMetadata = (isPublicPackage: boolean, mlPackage: MLPackageDto[]): void => {
        if (mlPackage.length) {
            if (isPublicPackage && !mlPackage[0].sourcePackageName?.length) {
                throw new CustomError('ML package and metadata should belong to public package', { data: { respCode: 106 } });
            } else if (!isPublicPackage && mlPackage[0].sourcePackageName?.length) {
                throw new CustomError('ML package and metadata should belong to non public package', { data: { respCode: 107 } });
            }
        }
    };

    const getMLpackageCloneMetadata = (fileContent: string, publicProject: ProjectDto, publicPackage: MLPackageDto[], publicPackageSourceVersion: string | undefined,
        mlpackageName: string | undefined): MLPackageCloneDto => {
        const mLpackageCloneMetadata = JSON.parse(fileContent) as MLPackageCloneDto;
        mLpackageCloneMetadata.projectId = currentProject?.id;
        mLpackageCloneMetadata.stagingUri = '';
        mLpackageCloneMetadata['mlPackageOwnedByAccountId'] = publicProject.accountId;
        mLpackageCloneMetadata['mlPackageOwnedByTenantId'] = publicProject.tenantId;
        mLpackageCloneMetadata['mlPackageOwnedByProjectId'] = publicProject.id;
        mLpackageCloneMetadata['sourcePackageId'] = publicPackage[0].id;
        mLpackageCloneMetadata['sourcePackageVersionId'] = publicPackageSourceVersion;
        mLpackageCloneMetadata['projectId'] = currentProject?.id;
        mLpackageCloneMetadata['name'] = mlpackageName;
        return mLpackageCloneMetadata;
    };

    return (
        <div style={{ width: '100%' }}>
            <Formik
                initialValues={{
                    packageSource: [] as FileToUpload[],
                    packageMetadata: [] as FileToUpload[],
                }}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onSubmit={async values => {

                    let mlpackageMetadata: MLPackageMetaDataDto = {};
                    let mlpackageCloneMetadata: MLPackageCloneDto = {};
                    let publicPackageNameFromMetadataFile = '';
                    let sourcePackageVersionFromMetadataFile = 0;

                    let fileContent: any;
                    const readUploadedFileAsText = (inputFile: File) => {
                        const temporaryFileReader = new FileReader();

                        return new Promise((resolve, reject) => {
                            temporaryFileReader.onerror = () => {
                                temporaryFileReader.abort();
                                reject(new DOMException('Problem parsing input file.'));
                            };

                            temporaryFileReader.onload = () => {
                                resolve(temporaryFileReader.result);
                            };
                            temporaryFileReader.readAsText(inputFile);
                        });
                    };

                    const metadataJSON: FileToUpload = values.packageMetadata[0];
                    let createdPackage = undefined;
                    try {
                        try {
                            fileContent = await readUploadedFileAsText(metadataJSON.file) as string;
                            mlpackageMetadata = getSanitizeMetadataJSON(fileContent);
                        } catch (error: any) {
                            const errorCode = getErrorCode(error);
                            throw new CustomError(error.message, { data: { respCode: errorCode } });
                        }

                        if (values.packageSource.length === 0 && !mlpackageMetadata.imagePath) {
                            throw new CustomError(t('PKGMANAGER_ML_PACKAGE_OOB_MISSING_MODEL'), { data: { respCode: 109 } });
                        }

                        if (isOobImport) {
                            createdPackage = await uploadAndCreateImportedPackage(mlpackageMetadata,
                                values.packageSource[0], importPackageType, '', undefined, false, authToken, azureStorageFQDNSuffix);
                            feedback.enqueueSuccess(t('feedback_create_success_mlpkg'));
                            history.push(currentProject ? generatePath(RoutePath.MLPACKAGES, { projectName: currentProject?.name }) : RoutePath.IMPORT_MLPACKAGE_OOB_LOGS);
                        } else {
                            const metadataJson = JSON.parse(fileContent);
                            mlpackageMetadata.name = metadataJson['mlPackageName'];
                            if ('sourcePackageName' in metadataJson) {
                                publicPackageNameFromMetadataFile = metadataJson['sourcePackageName'];
                                sourcePackageVersionFromMetadataFile = metadataJson['sourcePackageVersion'];
                            }

                            const allMlPackages: MLPackageDto[] | undefined = await getAllMlPackages(currentProject?.id, mlpackageMetadata.name);
                            const mlPackageByName: MLPackageDto[] = allMlPackages?.length ? allMlPackages.filter(pkg => pkg.name?.toLowerCase()
                                === mlpackageMetadata.name?.toLowerCase()) : [];
                            const mlpackageId = mlPackageByName?.length ? mlPackageByName[0].id : '';
                            const importType = mlpackageId ? 'ML_PACKAGE_VERSION_IMPORT' : 'ML_PACKAGE_IMPORT';
                            const feedbackMessage = mlpackageId ? 'feedback_create_success_mlpkg_version' : 'feedback_create_success_mlpkg';
                            if (!publicPackageNameFromMetadataFile) {
                                validatePackageMetadata(false, mlPackageByName);
                                createdPackage = await uploadAndCreateImportedPackage(mlpackageMetadata, values.packageSource[0], importType, mlpackageId, undefined, false, authToken, azureStorageFQDNSuffix);
                                feedback.enqueueSuccess(t(feedbackMessage));
                                routePage(mlpackageId ? mlpackageId : createdPackage?.id, mlpackageMetadata.name, undefined, undefined, false);
                            } else {
                                validatePackageMetadata(true, mlPackageByName);
                                const projectDtos: ProjectDto[] | undefined = await getPublicProjects();
                                if (projectDtos?.length) {
                                    for (const element of projectDtos) {
                                        const publicProject = element;
                                        const publicPackages: MLPackageDto[] | undefined = await getPublicPackages(publicProject.accountId, publicProject.tenantId, publicProject.id, 10000, currentProject?.id);
                                        const publicPackage: MLPackageDto[] | undefined = publicPackages?.filter(pkg => pkg.name?.toLowerCase() === publicPackageNameFromMetadataFile.toLowerCase());
                                        if (publicPackage?.length) {
                                            const publicPackageVersions: MLPackageDto | undefined = await getPublicPackageById(publicPackage[0].id, publicProject.accountId, publicProject.tenantId, publicProject.id, currentProject?.id);
                                            const publicPackageVersion: MLPackageVersionDto[] | undefined = publicPackageVersions?.mlPackageVersions?.filter(pv => pv?.version == sourcePackageVersionFromMetadataFile && pv.trainingVersion == 0);
                                            const feedbackMessage = mlpackageId ? 'feedback_create_success_mlpkg_version' : 'feedback_create_success_mlpkg';
                                            // In case no matching found, we will send Garbage UUID and backend will handle rest of the things
                                            const publicPackageSourceVersion: string | undefined = publicPackageVersion?.length ? publicPackageVersion[0].id : 'cbf7d4ae-34f9-11ed-a261-0242ac120002';
                                            mlpackageCloneMetadata = getMLpackageCloneMetadata(fileContent, publicProject, publicPackage, publicPackageSourceVersion, mlpackageMetadata.name);
                                            createdPackage = await uploadAndCreateImportedPackage(mlpackageMetadata, values.packageSource[0],
                                                importType, mlpackageId, mlpackageCloneMetadata, true, authToken, azureStorageFQDNSuffix);
                                            feedback.enqueueSuccess(t(feedbackMessage));
                                            routePage(mlpackageId ? mlpackageId : createdPackage?.id, mlpackageMetadata.name, publicPackage[0].id, publicProject, true);
                                            return true;
                                        }
                                    }
                                }
                                throw new CustomError('Failed to find public ML package by name in destination', { data: { respCode: 108 } });
                            }
                        }
                    } catch (error: any) {
                        feedback.enqueueError(extractErrorMessage(
                            error,
                            t('mlpkg_create_default_error'),
                            {
                                10602: {
                                    1: 'MLPackageVersion',
                                    0: createdPackage?.id || '',
                                },
                                20008: { 0: (createdPackage?.name || mlpackageMetadata?.name || '') },
                                20005: { 0: (createdPackage?.name || mlpackageMetadata?.name || '') },
                                20006: { 0: createdPackage?.id || '' },
                                20101: {},
                                20001: {},
                                10003: {},
                                20102: {},
                                103: {},
                                104: {},
                                105: {},
                                106: {},
                                107: {},
                                108: {},
                                109: {},
                                20300: {
                                    0: 'Language',
                                    1: 'MLPackageVersion',
                                },
                                10001: { 0: extractErrorFields(error) },
                                20309: {},
                                10004: {
                                    0: error?.respMsg?.split(' ')[0],
                                    1: error?.respMsg?.split(' ')[1],
                                },
                            },
                        ));
                    }
                }}
                validationSchema={Yup.object().shape({
                    packageMetadata: Yup.array()
                        .required(t('mlpkg_import_metadataFile_req'))
                        .min(1, t('mlpkg_import_metadataFile_req')),
                    packageSource: Yup.array().test('Validate source for import', '', function(value, context: Yup.TestContext) {
                        if (!isOobImport && (!value || value.length === 0)) {
                            return context.createError({
                                path: context.path,
                                message: t('mlpkg_create_pkgZipFile_req'),
                            });
                        }
                        return true;
                    }),
                })}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    props => {
                        const {
                            dirty,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            errors,
                        } = props;

                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                submitForm={submitForm}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                    />
                                }
                            >
                                <FormikErrorLabels errors={errors} />
                                <h2
                                    className='formTitle'
                                    id='page-title'
                                    tabIndex={0}
                                >
                                    {t('mlpkg_import_title')}
                                </h2>

                                <Label
                                    id="package-source-label"
                                    value={t('mlpkg_create_pkgSrc_label')}
                                    required={!isOobImport} />
                                <div data-testid="packageSource-testId">
                                    <Field
                                        name="packageSource"
                                        component={FileDropZone}
                                        id="packageSource"
                                        accept=".zip"
                                        disabledDropZone={isSubmitting}
                                        aria-label={t('mlpkg_create_pkgSrc_label')}
                                    />
                                </div>
                                <Label
                                    id="package-metadata-label"
                                    value={t('mlpkg_import_metadataSrc_label')}
                                    required />
                                <div data-testid="packageMetadata-testId">
                                    <Field
                                        name="packageMetadata"
                                        component={FileDropZone}
                                        id="packageMetadata"
                                        aria-label={t('mlpkg_import_metadataSrc_label')}
                                        accept=".json"
                                        disabledDropZone={isSubmitting}
                                        required
                                    />
                                </div>
                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </div >
    );
};
