import type { Theme } from '@mui/material';
import {
    Hidden,
    styled,
} from '@mui/material';
import type { ProjectDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';

import HeroInfoIcon from '../../components/HeroInfoIcon';
import Section from '../../components/Section';
import ServerSideTable from '../../components/Table';
import type {
    ColumnDefinition,
    ResponsiveColumnDefinition,
} from '../../components/Table/BaseTable/types';
import { WithVisibility } from '../../components/WithVisibility';
import URLManager from '../../config/URLManager';
import {
    DATASET,
    ML_PACKAGE,
    PIPELINE,
} from '../../constants/EntityTypes';
import { AppPermissions } from '../../enums/Authorization';
import { PermissionsContext } from '../../providers/PermissionsProvider';
import { ProjectsContext } from '../../providers/ProjectsProvider';
import { RoutePath } from '../../route/routeMap';
import {
    calculateDuration,
    dataFormatter,
} from '../../utils/CommonUtils';
import { dateFormatter } from '../../utils/DateFormatter';
import { setClearOrDoneIcon } from '../mlSkill/list/MlSkillPage';

const InlineTables = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',

    '& .table-container': {
        minWidth: '480px',
        width: '50%',
        flex: 1,
    },
});

const projectDetailsPageStyle = (theme: Theme) => ({
    fontSize: Tokens.FontFamily.FontMSize,
    paddingRight: '24px',
    paddingBottom: '24px',
    fontFamily: Tokens.FontFamily.FontNormal,
    fontStyle: 'normal',

    '& .projectSummaryContainer': {
        paddingRight: '24px',
        paddingLeft: '24px',
        marginTop: '24px',
    },

    '& .projectSummary': {
        marginBottom: '10px',
        minWidth: '432px',
        width: '100%',
        height: 'auto',
        border: `solid 1px ${theme.palette.semantic.colorBorder}`,
        boxSizing: 'border-box',
        boxShadow: 'none',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,

        '& .infoImage': {
            height: '96px',
            width: '96px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },

        '& .sectionContent': {
            width: '100%',
            padding: '16px',

            '& .sectionRow': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                lineHeight: Tokens.FontFamily.FontHeader2LineHeight,

                '& .rowData': {
                    width: '50%',
                    minWidth: '200px',
                    color: theme.palette.semantic.colorForeground,
                    alignItems: 'center',
                    display: 'flex',
                },

                '& .rowDescription': {
                    color: theme.palette.semantic.colorForeground,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                },

                '& .rowHeader': {
                    marginRight: '16px',
                    display: 'inline-block',
                    fontWeight: Tokens.FontFamily.FontWeightSemibold,
                },

                '& .rowValue': {
                    lineHeight: Tokens.FontFamily.FontMLineHeight,
                    fontWeight: Tokens.FontFamily.FontWeightDefault,

                    '& .MuiSvgIcon-root': { marginBottom: '-5px' },
                },

                '& .rowHeaderDesc': {
                    fontFamily: Tokens.FontFamily.FontNormal,
                    color: theme.palette.semantic.colorForeground,
                    fontWeight: Tokens.FontFamily.FontWeightSemibold,
                    marginRight: '16px',
                },

                '& .rowValueDesc': {
                    color: theme.palette.semantic.colorForeground,
                    fontWeight: Tokens.FontFamily.FontWeightDefault,
                    lineHeight: Tokens.FontFamily.FontMLineHeight,
                    marginRight: '10px',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    overflowWrap: 'break-word',
                },
            },
        },
    },

    '& .MuiSelect-root': { width: '28px' },

    '& .MuiTable-root': {
        width: '100%',
        minWidth: '420px',
    },
});

const ProjectDetailsPage = styled('div')(({ theme }) => projectDetailsPageStyle(theme));

const ProjectDetailsPageSection = styled(Section)(({ theme }) => projectDetailsPageStyle(theme));

interface ProjectSummaryProps {
    project: ProjectDto | undefined;
}

interface PipelineSourceVersionProps {
    sourceVersion?: string;
    sourceCustomVersion?: string;
}

// project summary
const ProjectSummary: React.FC<ProjectSummaryProps> = ({ project }) => {
    const {
        t, i18n,
    } = useTranslation();

    return (
        <div className="projectSummaryContainer">
            <div className="projectSummary">
                <Hidden mdDown>
                    <div
                        aria-hidden
                        className="infoImage">
                        <HeroInfoIcon />
                    </div>
                </Hidden>
                <div className="sectionContent">
                    <div className="sectionRow">
                        <div className="rowData">
                            <div className="rowHeader">
                                {t('project_details_created_by_label')}
                            </div>
                            <div className="rowValue">
                                {project?.createdBy}
                            </div>
                        </div>
                        <div className="rowData">
                            <div className="rowHeader">
                                {t('project_details_created_time_label')}
                            </div>
                            <div className="rowValue">
                                {dateFormatter(project?.createdOn?.toString() ? project?.createdOn?.toString() : '', i18n.language)}
                            </div>
                        </div>
                    </div>
                    <div className="sectionRow">
                        <div className="rowDescription">
                            <div className="rowHeaderDesc lastRow">
                                {t('project_details_description_label')}
                            </div>
                            <div className="rowValueDesc lastRow">
                                {project?.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ProjectDetailsPageFC: React.FC = () => {

    const {
        t, i18n,
    } = useTranslation();

    // Dataset section
    const datasetDataMapper: ColumnDefinition[] = [
        {
            header: `${t('common_name_label')}`,
            accessorKey: 'name',
            enableSorting: true,
        },
        {
            header: `${t('common_last_modified_label')}`,
            accessorKey: 'modifiedOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
        },
    ];

    // Packages section
    const packagesDataMapper: ColumnDefinition[] = [
        {
            header: `${t('common_name_label')}`,
            accessorKey: 'name',
            enableSorting: true,
        },
        {
            header: `${t('common_deployed_label')}`,
            accessorKey: 'status',
            enableSorting: true,
            cell: ({ cell: { getValue } }): React.ReactElement => setClearOrDoneIcon(getValue() === 'DEPLOYED' ? 1 : 0, t),
        },
    ];

    // Pipeline run section
    const pipelineRunsDataMapper: ResponsiveColumnDefinition[] = [
        {
            header: `${t('common_package_name_label')}`,
            accessorKey: 'sourceName',
            enableSorting: true,
        },
        {
            id: 'pipeline.pipelineType',
            header: `${t('pipeline_list_pipeline_type_header')}`,
            accessorKey: 'pipeline.pipelineType',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => t(`PIPELINE_${getValue()}`),
        },
        {
            id: 'pipeline.sourceVersion',
            header: `${t('common_version_label')}`,
            accessorKey: 'pipeline.sourceVersion',
            enableSorting: true,
            cell: ({ row }): string | undefined => {
                const pipelineSourceVersion: PipelineSourceVersionProps = row.original;
                return pipelineSourceVersion.sourceCustomVersion ? pipelineSourceVersion.sourceCustomVersion : pipelineSourceVersion.sourceVersion;
            },
        },
        {
            header: `${t('common_status_label')}`,
            accessorKey: 'displayStatus',
            cell: ({ cell: { getValue } }): string => dataFormatter(t(`RUNS_${getValue()}`), i18n.language),
        },
        {
            header: `${t('common_created_label')}`,
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
        },
        {
            header: `${t('pipeline_list_duration_header')}`,
            accessorKey: '',
            cell: (data): string => calculateDuration(data.row.original),
        },
    ];

    const { projectName } = useParams<{ projectName: string }>();
    const history = useHistory();

    const { state } = useContext(ProjectsContext);
    const currentProject = React.useMemo(() => state.currentProject, [ state ]);
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProject?.id ?? '']?.permissionSet || [];

    const datasetsUrl = React.useMemo(() => URLManager.url().apiTrainer + '/datasets?projectId=' + currentProject?.id, [ currentProject ]);

    const packagesUrl = React.useMemo(() => URLManager.url().apiPkgManager + '/mlpackages?projectId=' + currentProject?.id, [ currentProject ]);

    const pipelineRunsUrl = React.useMemo(() => URLManager.url().apiTrainer + '/runs?sortBy=createdOn&sortOrder=DESC&projectId=' + currentProject?.id, [ currentProject ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDataSetClicked = useCallback((rowInfo: any): void => {
        history.push(generatePath(RoutePath.DATASET_FOLDER_VIEW, {
            projectName,
            datasetId: rowInfo.original.id,
            datasetFolderName: rowInfo.original.name,
            currentLocation: rowInfo.original.name,
        }));
    }, [ projectName ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMLPackageClicked = (row: any): void => {
        history.push({
            pathname: generatePath(RoutePath.MLPACKAGE_VERSION, {
                projectName,
                mlpackageId: row.original.id,
                mlpackageName: row.original.name,
            }),
            state: {
                data: {
                    sourcePackageId: row.original.sourcePackageId,
                    mlPackageOwnedByAccountId: row.original.mlPackageOwnedByAccountId,
                    mlPackageOwnedByTenantId: row.original.mlPackageOwnedByTenantId,
                    mlPackageOwnedByProjectId: row.original.mlPackageOwnedByProjectId,
                },
            },
        });
    };

    return currentProject?.id ? (
        <ProjectDetailsPage>
            <ProjectSummary project={currentProject} />
            <InlineTables>
                <div className="table-container">
                    <WithVisibility visible={(permissions.indexOf(AppPermissions.MLStorage_View) > -1)} >
                        <Section
                            title={t('project_details_datasets_title')}
                            titleNavigationLink={generatePath(RoutePath.DATASETS, { projectName: currentProject.name })}>
                            <ServerSideTable
                                url={datasetsUrl}
                                searchable
                                searchKey="name"
                                totalKey="data.totalCount"
                                dataKey="data.dataList"
                                mapper={datasetDataMapper}
                                onTableCellClicked={handleDataSetClicked}
                                entityName={DATASET}
                                keyColumnIndex={0}
                                level="dashboard_pagesize"
                            />
                        </Section>
                    </WithVisibility>
                </div>
                <div className="table-container">
                    {(permissions.indexOf(AppPermissions.MLPackages_View) > -1) ?
                        (<ProjectDetailsPageSection
                            title={t('project_details_packages_title')}
                            titleNavigationLink={generatePath(RoutePath.MLPACKAGES, { projectName: currentProject.name })}>
                            <ServerSideTable
                                url={packagesUrl}
                                searchable
                                searchKey="name"
                                totalKey="data.totalCount"
                                dataKey="data.dataList"
                                mapper={packagesDataMapper}
                                onTableCellClicked={handleMLPackageClicked}
                                entityName={ML_PACKAGE}
                                keyColumnIndex={0}
                                level="dashboard_pagesize"
                            />
                        </ProjectDetailsPageSection>) : null}
                </div>
            </InlineTables>
            {(permissions.indexOf(AppPermissions.MLPipeline_View) > -1) ?
                (<ProjectDetailsPageSection
                    title={t('project_details_pipeline_runs_title')}
                    titleNavigationLink={generatePath(RoutePath.PIPELINES, { projectName: currentProject.name })}>
                    <ServerSideTable
                        url={pipelineRunsUrl}
                        totalKey="data.totalCount"
                        dataKey="data.dataList"
                        mapper={pipelineRunsDataMapper}
                        entityName={PIPELINE}
                        keyColumnIndex={0}
                        level="dashboard_pagesize"
                    />
                </ProjectDetailsPageSection>) : null}
        </ProjectDetailsPage>
    ) : null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect(() => ({}))(ProjectDetailsPageFC);
