import type { TextFieldProps } from 'formik-mui';
import { TextField } from 'formik-mui';
import React from 'react';

export default (props: JSX.IntrinsicAttributes & TextFieldProps & { requiredField?: boolean; isValid?: boolean }) => {
    const {
        requiredField, isValid, ...rest
    } = props;

    return (<TextField
        {...rest}
        aria-required={requiredField}
        required={requiredField}
        tabIndex={-1}
        InputProps={{
            'inputProps': {
                'aria-label': props['aria-label'],
                'aria-labelledby': props['aria-labelledby'],
                'aria-describedby': (isValid ?? true) ? props['aria-describedby'] : `${props.field.name}-error-label`,
                'aria-required': requiredField,
            },
        }} />);
};
