import {
    styled,
    Typography,
} from '@mui/material';
import type { DatasetDto } from '@uipath/aifabric';
import {
    Field,
    Formik,
} from 'formik';
import { Switch } from 'formik-mui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';
import * as Yup from 'yup';

import {
    createDataset,
    getDatasetById,
    updateDataset,
} from '../../../api/client/datasetManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import TooltipContainer from '../../../components/AccessibleTooltip/TooltipContainer';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import { Validation as val } from '../../../constants/validation';
import type FeatureFlagManager from '../../../feature-flag/FeatureFlagManager';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import {
    extractErrorMessage,
    isBlobEncryptionEnabled,
    isPublicEndpointEnabled,
} from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';

const DataSetCreateRoot = styled('div')({
    width: '100%',
    '& .publicDS': { marginTop: '20px' },
});

const Header = styled('h2')(({ theme }) => ({ color: theme.palette.semantic.colorForeground }));
const ErrorLabel = styled('div')({
    marginTop: '5px',
    marginBottom: '5px',
});

interface DatasetCreateProps {
    isOnPrem: boolean;
    featureManager: FeatureFlagManager;
}

export const DatasetCreateFC: React.FC<DatasetCreateProps> = ({
    isOnPrem, featureManager,
}) => {

    const { datasetId } = useParams<{ datasetId: string }>();
    const { t } = useTranslation();
    const history = useHistory();
    const feedback = useFeedback();

    const [ dataset, setDataset ] = React.useState<DatasetDto | undefined>();
    const { state } = useContext(ProjectsContext);
    const currentProject = state.currentProject;

    React.useEffect(() => {
        if (datasetId) {
            getDatasetById(datasetId, currentProject?.id)
                .then(dataset => {
                    setDataset(dataset);
                    return true;
                })
                .catch((error => {
                    logger.error({
                        identifier: 'Dataset',
                        message: 'Error getting dataset by id ' + datasetId,
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(error, t('feedback_get_dataset_error'), { 60104: { 0: datasetId } }));
                }));
        }
    }, [ datasetId ]);

    if (datasetId && !dataset) {
        return <h2>
            {t('dataset_edit_loading')}
        </h2>;
    }

    return (
        <DataSetCreateRoot>
            <Formik
                initialValues={{
                    datasetName: dataset ? dataset.name : '',
                    datasetDescription: dataset ? dataset.description : '',
                    publicDS: dataset ? dataset.public : false,
                    encryptedDS: dataset ? dataset.encrypted : false,
                }}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onSubmit={async values => {
                    try {
                        let responseDataset;
                        if (datasetId) {
                            responseDataset = await updateDataset({
                                name: values.datasetName,
                                description: values.datasetDescription,
                                projectId: currentProject?.id,
                                public: values.publicDS,
                            },
                            datasetId).then((dataset) => {
                                feedback.enqueueSuccess(t('feedback_edit_success_dataset'));
                                return dataset;
                            });
                        } else {
                            responseDataset = await createDataset({
                                name: values.datasetName,
                                description: values.datasetDescription,
                                projectId: currentProject?.id,
                                public: values.publicDS,
                                encrypted: values.encryptedDS,
                            }).then((dataset) => {
                                feedback.enqueueSuccess(t('feedback_create_success_dataset'));
                                return dataset;
                            });
                        }

                        // eslint-disable-next-line
                        history.push(generatePath(RoutePath.DATASET_FOLDER_VIEW, {
                            datasetId: responseDataset?.id,
                            datasetFolderName: responseDataset?.name,
                            projectName: currentProject?.name,
                            currentLocation: responseDataset?.name,
                        }));

                    } catch (error) {
                        feedback.enqueueError(extractErrorMessage(error, t('COMMON_UNHANDLED_EXCEPTION'), {
                            10006: {
                                0: 'Dataset',
                                1: values.datasetName || '',
                            },
                            10007: {
                                0: 'Dataset',
                                1: values.datasetName || '',
                            },
                            60101: {},
                            10602: {
                                1: 'Dataset',
                                0: datasetId,
                            },
                            60104: { 0: datasetId },
                            60102: { 0: datasetId },
                        }));
                    }
                }}
                validationSchema={Yup.object().shape({

                    datasetName: Yup.string()
                        .required(t('dataset_create_dsName_req'))
                        .matches(val.datasetCreateNameValidation, t('dataset_name_validation_error'))
                        .max(val.datasetCreateDatasetNameMax,
                            t('dataset_create_dsName_max', { max: val.datasetCreateDatasetNameMax })),

                    datasetDescription: Yup.string()
                        .max(val.datasetCreateDescriptionMax,
                            t('dataset_create_dsDesc_max', { max: val.mlpackageCreateDescriptionMax })),
                })}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    (props) => {
                        const {
                            dirty,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            errors,
                            values,
                        } = props;
                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                submitForm={submitForm}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                        submitButtonText={datasetId ? t('form_edit_button_text') : undefined} />
                                }
                            >
                                <FormikErrorLabels errors={errors} />
                                <Header
                                    aria-label={`${dataset ? t('dataset_edit_title') : t('dataset_create_title')} title`}
                                    id='page-title'
                                    tabIndex={0}
                                >
                                    {datasetId ? t('dataset_edit_title') : t('dataset_create_title')}
                                </Header>

                                <Label
                                    value={t('dataset_create_dsName_label')}
                                    required
                                    aria-label={`${t('dataset_create_dsName_label')} label`}>
                                    <ErrorLabel>
                                        <Typography>
                                            {t('dataset_name_validation_error')}
                                        </Typography>
                                    </ErrorLabel>
                                </Label>
                                <Field
                                    component={AccessibleFormikInput}
                                    className="textFeild min-width-override"
                                    type="text"
                                    requiredField
                                    name="datasetName"
                                    variant="outlined"
                                    color="secondary"
                                    isValid={!errors['datasetName']}
                                    placeholder={t('dataset_create_dsName_ph')}
                                    aria-label={`${t('dataset_create_dsName_label')} text field`}
                                    autoComplete="off"
                                    disabled={datasetId ? true : false || isSubmitting}
                                />

                                <Label
                                    value={t('dataset_create_dsDesc_label')}
                                    aria-label={`${t('dataset_create_dsDesc_label')} label`} />
                                <Field
                                    name="datasetDescription"
                                    type="text"
                                    component={AccessibleFormikInput}
                                    className="min-width-override"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder={t('dataset_create_dsDesc_ph')}
                                    aria-label={`${t('dataset_create_dsDesc_label')} text field`}
                                    autoComplete="off"
                                    multiline
                                    rows={6}
                                />

                                {isPublicEndpointEnabled(isOnPrem, featureManager) ? (
                                    <div className="publicDS">
                                        <TooltipContainer
                                            title={t('tooltip_datasets_make_dataset_public')}
                                            customStyle={{ marginTop: '20px' }}>
                                            <Label value={t('dataset_create_publicDS_label')} />
                                        </TooltipContainer>
                                        <div
                                            aria-label={`${t('dataset_create_publicDS_label')} switch ${values.publicDS ? `checked` : `unchecked`
                                            }`}
                                        >
                                            <Field
                                                name="publicDS"
                                                component={Switch}
                                                color="secondary"
                                                type="checkbox"
                                                inputProps={{ 'aria-label': t('ally_public_ds_switch') }}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>

                                ) : (
                                    <div />
                                )}
                                {isBlobEncryptionEnabled(featureManager) ? (
                                    <div className="encryptedDataset">
                                        <TooltipContainer
                                            title={t('tooltip_datasets_encrypt_dataset')}
                                            customStyle={{ marginTop: '20px' }}>
                                            <Label value={t('dataset_create_encrypted_label')} />
                                        </TooltipContainer>
                                        <div
                                            aria-label={`${t('dataset_create_encrypted_label')} switch ${values.encryptedDS ? `checked` : `unchecked`
                                            }`}
                                        >
                                            <Field
                                                name="encryptedDS"
                                                component={Switch}
                                                color="secondary"
                                                type="checkbox"
                                                inputProps={{ 'aria-label': t('ally_encrypted_ds_switch') }}
                                                data-testid="encryptedDS-testid"
                                                disabled={isSubmitting || datasetId}
                                            />
                                        </div>
                                    </div>)
                                    : (<></>)}

                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </DataSetCreateRoot>
    );
};

export const DatasetCreate = connect((state: any) => ({
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
    featureManager: state.featureManagement.featureManager,
}))(DatasetCreateFC);
