import type { BlobMetadata } from '@uipath/aifabric';
import prettyBytes from 'pretty-bytes';

import type { ResponsiveColumnDefinition } from '../components/Table/BaseTable/types';
import i18n from '../il8n';
import type { PackageVersionProps } from '../pages/mlPackageVersion/MLPackageVersionsPage';
import {
    dataFormatter,
    getFileOrFolderNameForPath,
} from '../utils/CommonUtils';
import { dateFormatter } from '../utils/DateFormatter';

const TableHeaderMappings: {
    VersionsList: ResponsiveColumnDefinition[];
    DatasetsFolderMappingsBase: ResponsiveColumnDefinition[];
    DatasetsBase: ResponsiveColumnDefinition[];
} = {
    VersionsList: [
        {
            header: `${i18n.t('common_package_version_label')}`,
            accessorKey: 'versions',
            enableSorting: true,
            cell: ({ row }): string => {
                const packageVersionDetail: PackageVersionProps = row.original;

                if (packageVersionDetail.customVersion) {
                    return packageVersionDetail.customVersion;
                }
                return packageVersionDetail.version?.toString() || '';

            },
        },
        {
            header: `${i18n.t('common_train_version_label')}`,
            accessorKey: 'trainingVersions',
            cell: ({ row }): string => {
                const packageVersionDetail: PackageVersionProps = row.original;
                return packageVersionDetail.trainingVersion?.toString() || '';
            },
        },
        {
            header: `${i18n.t('common_created_time_label')}`,
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
        },
        {
            header: `${i18n.t('common_change_log_label')}`,
            accessorKey: 'changeLog',
        },
    ],
    DatasetsFolderMappingsBase: [
        {
            header: `${i18n.t('common_name_label')}`,
            accessorKey: 'blobName',
            cell: ({ cell }): string => getFileOrFolderNameForPath(cell),
        },
        {
            header: `${i18n.t('common_size_label')}`,
            accessorKey: 'blobSize',
            cell: ({
                cell: {
                    getValue, row,
                },
            }): string => {
                const blobInfo: BlobMetadata = row.original;
                const value = getValue();
                if (blobInfo && blobInfo.blobType !== 'DIRECTORY' && typeof value !== 'undefined' && value) {
                    return prettyBytes(value as number);
                }
                return '--';
            },
        },
        {
            header: `${i18n.t('common_type_label')}`,
            accessorKey: 'blobType',
            cell: ({ cell: { getValue } }: any): string => dataFormatter(i18n.t(`label_${getValue()?.toLowerCase()}`), i18n.language),
        },
        {
            header: `${i18n.t('common_created_label')}`,
            accessorKey: 'createTime',
            cell: ({ cell: { getValue } }: any): string => dateFormatter(getValue(), i18n.language),
        },
    ],
    DatasetsBase: [
        {
            header: `${i18n.t('common_name_label')}`,
            accessorKey: 'name',
            enableSorting: true,
        },
        {
            header: `${i18n.t('common_description_label')}`,
            accessorKey: 'description',
            enableSorting: true,
        },
        {
            header: `${i18n.t('common_created_label')}`,
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }: any): string => dateFormatter(getValue(), i18n.language),
        },
    ],
};
export default TableHeaderMappings;
