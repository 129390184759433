import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import { t } from 'i18next';
import type { ReactNode } from 'react';
import React from 'react';

interface LabelProps {
    value: string;
    id?: string;
    htmlFor?: string;
    required?: boolean;
    children?: ReactNode;
}

const StyledLabel = styled('label')<{ required?: boolean }>(({
    theme, required,
}) => ({
    display: 'block',
    marginTop: '24px',
    marginBottom: '5px',
    fontFamily: Tokens.FontFamily.FontNormal,
    color: theme.palette.semantic.colorForeground,
    fontSize: Tokens.FontFamily.FontMSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    fontWeight: 600,
    fontStyle: 'normal',

    ...(required && {
        '&:after': {
            content: '"*"',
            color: 'red',
        },
    }),
}));

/**
 * Hidden span to make screen readers announce that the field is required. Cannot use display: none or visibility: hidden
 * since the screen reader would not announce the content. Instead, we use width: 0 and opacity: 0 to hide the content.
 */
const HiddenRequiredSpan = styled('span')({
    display: 'inline-block',
    width: 0,
    opacity: 0,
});

const Label: React.FC<LabelProps> = (props) => <>
    <StyledLabel
        id={props.id}
        htmlFor={props.htmlFor}
        required={props.required}>
        {props.value}
        {props.required ? (
            <HiddenRequiredSpan>
                {`(${t('common_required')})`}
            </HiddenRequiredSpan>
        ) : null}

    </StyledLabel>
    {props.children ? props.children : null}
</>;

export default Label;
