import {
    MenuItem,
    styled,
    Typography,
} from '@mui/material';
import type { MLPackageLanguageVersionDto } from '@uipath/aifabric';
import {
    Field,
    Formik,
} from 'formik';
import { Switch } from 'formik-mui';
import React, {
    useCallback,
    useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import {
    getMLPackageLanguages,
    getMLPackageLanguageVersions,
    getPackageById,
    uploadAndCreatePackage,
    uploadAndCreatePackageVersion,
} from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import TooltipContainer
    from '../../../components/AccessibleTooltip/TooltipContainer';
import { FileDropZone } from '../../../components/fileDropzone/FileDropZone';
import type FileToUpload from '../../../components/fileDropzone/FileToUpload';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../../constants/BlobStorageConstants';
import { ML_PACKAGE_UPLOAD_TIME_EVENT } from '../../../constants/TelemetryConstants';
import { Validation as val } from '../../../constants/validation';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import {
    extractErrorFields,
    extractErrorMessage,
} from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';

interface InputType {
    name: string;
    type: 'JSON' | 'FILE' | 'FILE_LIST' | undefined;
}

const ErrorLabel = styled('div')({
    marginTop: '5px',
    marginBottom: '5px',
});

interface MLPackageCreateProps {
    isPublic: boolean;
    authToken: string;
    azureStorageFQDNSuffix: string;
}
type MLPackageLanguageVersionUiDto = Omit<MLPackageLanguageVersionDto, 'version'> & {
    version: string;
};

const MLPackageCreate: React.FC<MLPackageCreateProps> = ({
    isPublic, authToken, azureStorageFQDNSuffix,
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const feedback = useFeedback();

    const [ inputTypes ] = React.useState<InputType[]>([
        {
            name: 'Json',
            type: 'JSON',
        },
        {
            name: 'file',
            type: 'FILE',
        },
        {
            name: 'files',
            type: 'FILE_LIST',
        },
    ]);

    const [ mlpackageLanguages, setMLPackageLanguages ] = React.useState<MLPackageLanguageVersionUiDto[]>([]);
    const [ mlpackageLanguageVersions, setLanguageVersions ] = React.useState<any[]>([]);
    const [ defaultMlPackageLanguage, setdefaultMlPackageLanguage ] = React.useState<any>();
    const mlPackageLanguageVersionDisabled = (mlpackageLanguageVersions.length === 0 ? true : false);

    // defaults
    const [ inputType, setInputType ] = React.useState<'JSON' | 'FILE' | 'FILE_LIST' | undefined>('JSON');
    const [ inputDescription, setInputDescription ] = React.useState('');
    const [ outputDescription, setOutputDescription ] = React.useState('');
    const [ requireGpu, setRequireGpu ] = React.useState(false);
    const [ isCustomVersionToggle ] = React.useState(false);
    const [ enableTraining, setEnableTraining ] = React.useState(false);
    const [ hasCustomPackageVersion, setHasCustomPackageVersion ] = React.useState<false | true | undefined>(false);

    // set Python36 as default Language
    const [ language, setLanguage ] = React.useState('');
    const [ languageDefaultVersion, setLanguageDefaultVersion ] = React.useState('');

    const {
        projectName, mlpackageName, mlpackageId,
    } = useParams<{ projectName: string; mlpackageName: string; mlpackageId: string }>();
    const { state: projectsState } = useContext(ProjectsContext);
    const currentProject = projectsState.currentProject;

    const blacklistedLanguages = [ 'PYTHON2X', 'PYTHON3X', 'PYTHON27', 'PYTHON36', 'MOJO_PIPELINE' ];

    const defaultLanguage = 'PYTHON39';

    React.useEffect(() => {
        setMLPackageLanguages([]);
        getMLPackageLanguages()
            .then((langResponse: MLPackageLanguageVersionDto[] | undefined) => {
                const mlpackageLanguages = langResponse?.map((node) => ({
                    ...node,
                    version: '' + node.version,
                }));
                if (mlpackageLanguages && mlpackageLanguages.length) {
                    // set language list
                    setMLPackageLanguages(mlpackageLanguages);

                    if (!mlpackageId) {
                        const lang = mlpackageLanguages.find((lang) => lang.mlPackageLanguage === defaultLanguage);

                        if (lang) {
                            setdefaultMlPackageLanguage(lang);
                            setLanguageDefaultVersion('' + lang.version);
                            setLanguage('' + lang.mlPackageLanguage);
                        }
                    }
                }
                return true;
            })
            .catch(error => {
                logger.error({
                    identifier: 'MLPackage Languages',
                    message: 'Error getting mlpackage languages',
                    error,
                });
            });
    }, []);

    React.useEffect(() => {
        if (mlpackageId && mlpackageLanguages && language && !defaultMlPackageLanguage) {
            // set default language
            mlpackageLanguages.forEach(lang => {
                if (lang.mlPackageLanguage === language) {
                    setdefaultMlPackageLanguage(lang);
                }
            });
        }
    }, [ mlpackageId, mlpackageLanguages, language ]);

    const updateLanguage = useCallback((lang: string) => {
        if (!isPublic || !lang) {
            return;
        }
        setLanguageVersions([]);
        getMLPackageLanguageVersions(lang)
            .then((mlpackageLanguageVersions: MLPackageLanguageVersionDto[] | undefined) => {
                if (mlpackageLanguageVersions && mlpackageLanguageVersions.length) {
                    setLanguageVersions(mlpackageLanguageVersions.map((langVersion: MLPackageLanguageVersionDto) =>
                        ({ version: langVersion.version?.toString() })));
                }
                return true;
            })
            .catch(error => {
                logger.error({
                    identifier: 'MLPackage Language Versions',
                    message: 'Error getting mlpackage language versions',
                    error,
                });
            });
    }, []);

    React.useEffect(() => {
        updateLanguage(language);
    }, [ language ]);

    React.useEffect(() => {
        if (mlpackageName && mlpackageId) {
            getPackageById(mlpackageId, currentProject?.id, false).then((mlPackage) => {
                if (mlPackage) {
                    setInputDescription(mlPackage.latestVersionInputDescription ? mlPackage.latestVersionInputDescription : '');
                    setOutputDescription(mlPackage.latestVersionOutputDescription ? mlPackage.latestVersionOutputDescription : '');
                    if (!defaultMlPackageLanguage && !language) {
                        const lang = mlPackage.latestVersionLanguage || '';
                        const languageNode = mlpackageLanguages.find((node) => node.mlPackageLanguage === lang);
                        if (languageNode) {
                            setLanguage(lang);
                            setLanguageDefaultVersion(languageNode.version);
                        }

                    }
                    setInputType(mlPackage.inputType);
                    setHasCustomPackageVersion(mlPackage.hasCustomVersion);
                    setRequireGpu(mlPackage.latestVersionGpu && mlPackage.latestVersionGpu > 0 ? true : false);
                    setEnableTraining(mlPackage.retrainable ? true : false);
                }
                return true;
            })
                .catch(error => {
                    logger.error({
                        identifier: 'ML Package create',
                        message: 'Error getting package by ID',
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(error, t('ml_package_loading_error'), {
                        10602: {
                            1: 'MLPackage',
                            0: mlpackageId,
                        },
                    }));
                });
        }
    }, [ mlpackageName, mlpackageId, feedback, t ]);

    return language ? (
        <div
            style={{ width: '100%' }}>
            <Formik
                initialValues={{
                    packageName: mlpackageName ? mlpackageName : '',
                    packageSource: [] as FileToUpload[],
                    inputType,
                    inputDescription,
                    outputDescription,
                    language,
                    languageVersion: languageDefaultVersion,
                    packageDescription: '',
                    changeLog: '',
                    requireGpu,
                    isCustomVersionToggle,
                    customVersion: undefined,
                    retrainable: enableTraining,
                }}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onSubmit={async values => {
                    try {
                        let createdPackage = undefined;
                        const uploadStartTime = Date.now();

                        if (mlpackageName && mlpackageId) {
                            await uploadAndCreatePackageVersion({
                                gpu: values.requireGpu ? 1 : 0,
                                displayName: values.packageName,
                                name: values.packageName,
                                description: values.packageDescription,
                                inputDescription: values.inputDescription,
                                outputDescription: values.outputDescription,
                                mlPackageLanguage: values.language,
                                languageVersion: values.languageVersion,
                                inputType: values.inputType,
                                retrainable: values.retrainable,
                                changeLog: values.changeLog,
                                projectId: currentProject?.id,
                                customVersion: values.customVersion,
                            }, mlpackageId, values.packageSource[0], authToken, azureStorageFQDNSuffix);

                            feedback.enqueueSuccess(t('feedback_create_success_mlpkg_version'));
                        } else {
                            createdPackage = await uploadAndCreatePackage({
                                gpu: values.requireGpu ? 1 : 0,
                                displayName: values.packageName,
                                name: values.packageName,
                                description: values.packageDescription,
                                inputDescription: values.inputDescription,
                                outputDescription: values.outputDescription,
                                mlPackageLanguage: values.language,
                                languageVersion: values.languageVersion,
                                inputType: values.inputType,
                                retrainable: values.retrainable,
                                projectId: currentProject?.id,
                                customVersion: values.customVersion,
                            }, values.packageSource[0], authToken, azureStorageFQDNSuffix);

                            feedback.enqueueSuccess(t('feedback_create_success_mlpkg'));
                        }

                        /* Add telemtery event for ML package upload time */
                        logger.customEvents({
                            identifier: ML_PACKAGE_UPLOAD_TIME_EVENT,
                            message: 'Time Taken to upload ML Package or ML Package version',
                            payload: {
                                name: values.packageName,
                                projectId: currentProject?.id,
                                time: Date.now() - uploadStartTime,
                                mlPackageLanguage: values.language,
                                mlPackageLanguageVersion: values.languageVersion,
                            },
                        }, ML_PACKAGE_UPLOAD_TIME_EVENT);

                        history.push(generatePath(RoutePath.MLPACKAGE_VERSION, {
                            projectName,
                            mlpackageName: mlpackageName ? mlpackageName : values.packageName,
                            mlpackageId: mlpackageId ? mlpackageId : createdPackage?.id,
                        }));
                    } catch (error) {
                        feedback.enqueueError(extractErrorMessage(
                            error,
                            (mlpackageName && mlpackageId) ?
                                t('mlpkg_version_create_default_error') :
                                t('mlpkg_create_default_error'),
                            {
                                10602: {
                                    1: 'MLPackageVersion',
                                    0: mlpackageId,
                                },
                                20008: { 0: (mlpackageName || values.packageName) },
                                20005: { 0: (mlpackageName || values.packageName) },
                                20006: { 0: mlpackageId },
                                20101: {},
                                20001: {},
                                20300: {
                                    0: (values.language?.toString() || 'Language'),
                                    1: 'MLPackageVersion',
                                },
                                10001: { 0: extractErrorFields(error) },
                            },
                        ));
                    }
                }}
                validationSchema={Yup.object().shape({

                    packageName: Yup.string()
                        .required(t('mlpkg_create_pkgName_req'))
                        .matches(val.mlPackageCreateNameValidation, t('ml_package_name_validation_error'))
                        .min(val.mlpackageCreatePackageNameMin,
                            t('mlpkg_create_pkgName_max', {
                                max: val.mlpackageCreatePackageNameMax,
                                min: val.mlpackageCreatePackageNameMin,
                            }))
                        .max(val.mlpackageCreatePackageNameMax,
                            t('mlpkg_create_pkgName_max', {
                                max: val.mlpackageCreatePackageNameMax,
                                min: val.mlpackageCreatePackageNameMin,
                            })),
                    packageSource: Yup.array()
                        .required(t('mlpkg_create_pkgZipFile_req'))
                        .min(1, t('mlpkg_create_pkgZipFile_req')),
                    customVersion: Yup.string().test('Validate custom version', '', function(value: any, context: Yup.TestContext) {
                        if ((context.parent.isCustomVersionToggle && !value) || (mlpackageId && hasCustomPackageVersion && !value)) {
                            return context.createError({
                                path: context.path,
                                message: t('custom_package_version_missing'),
                            });
                        }

                        if (context.parent.isCustomVersionToggle) {
                            if (value.length > val.customVersionLengthValidation) {
                                return context.createError({
                                    path: context.path,
                                    message: t('custom_package_version_name_max_length', { max: val.datasetCreateDatasetNameMax }),
                                });
                            }
                            if (!value.match(val.customVersionNameValidation)) {
                                return context.createError({
                                    path: context.path,
                                    message: t('custom_package_version_invalid_name'),
                                });
                            }
                        }
                        return true;
                    }),

                    inputDescription: Yup.string().notRequired()
                        .max(val.mlpackageCreateDescriptionMax,
                            t('mlpkg_create_inputDesc_max', { max: val.mlpackageCreateDescriptionMax })),

                    packageDescription: Yup.string().notRequired()
                        .max(val.mlpackageCreateDescriptionMax,
                            t('mlpkg_create_pkgDesc_max', { max: val.mlpackageCreateDescriptionMax })),

                    outputDescription: Yup.string().notRequired()
                        .max(val.mlpackageCreateDescriptionMax,
                            t('mlpkg_create_outputDesc_max', { max: val.mlpackageCreateDescriptionMax })),

                    language: Yup.string()
                        .required(t('mlpkg_create_language_label')),

                    languageVersion: Yup.string()
                        .required(t('mlpkg_create_language_version_label')),
                })}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    props => {
                        const {
                            dirty,
                            setFieldValue,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            errors,
                            values,
                        } = props;

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const languageNode = mlpackageLanguages.filter(c => (c.mlPackageLanguage === values.language))[0];

                        // set to default
                        if (!values.languageVersion) {
                            setFieldValue('languageVersion', '' + languageNode?.version);
                        }

                        if (language !== values.language) {
                            setLanguage(values.language);
                            if (values.languageVersion !== ('' + languageNode?.version)) {
                                setFieldValue('languageVersion', '' + languageNode.version);
                            }
                        }

                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                submitForm={submitForm}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                    />
                                }
                            >
                                <FormikErrorLabels errors={errors} />
                                {
                                    (mlpackageName && mlpackageId) ?
                                        (<div>
                                            <div style={{ display: 'inline-block' }}>
                                                <h2 className='formTitle'>
                                                    {t('mlpkg_create_new_version_title')}
                                                </h2>
                                            </div>
                                            <div style={{
                                                display: 'inline-block',
                                                marginLeft: '5px',
                                            }}>
                                                {mlpackageName}
                                            </div>
                                        </div>) :
                                        <h2
                                            className='formTitle'
                                            id='page-title'
                                            tabIndex={0}
                                        >
                                            {t('mlpkg_create_title')}
                                        </h2>
                                }

                                {
                                    (mlpackageName && mlpackageId) ? null : (
                                        <div>
                                            <Label
                                                value={t('mlpkg_create_pkgName_label')}
                                                required>
                                                <ErrorLabel>
                                                    <Typography>
                                                        {t('ml_package_name_validation_error')}
                                                    </Typography>
                                                    <Typography>
                                                        {t('mlpkg_create_pkgName_max', {
                                                            max: val.mlpackageCreatePackageNameMax,
                                                            min: val.mlpackageCreatePackageNameMin,
                                                        })}
                                                    </Typography>
                                                </ErrorLabel>
                                            </Label>
                                            <Field
                                                name="packageName"
                                                type="text"
                                                component={AccessibleFormikInput}
                                                requiredField
                                                isValid={!errors['packageName']}
                                                className="textFeild min-width-override"
                                                variant="outlined"
                                                color="secondary"
                                                placeholder={t('mlpkg_create_pkgName_ph')}
                                                aria-label={`${t('mlpkg_create_pkgName_ph')} text field`}
                                                disabled={(mlpackageName && mlpackageId) ? true : false || isSubmitting}
                                                autoComplete="off"
                                            />
                                        </div>)
                                }

                                <Label
                                    id="packageSource-label"
                                    value={t('mlpkg_create_pkgSrc_label')}
                                    required />
                                <Field
                                    name="packageSource"
                                    component={FileDropZone}
                                    id="packageSource"
                                    accept=".zip"
                                    required
                                    disabledDropZone={isSubmitting}
                                    aria-label={t('mlpkg_create_pkgSrc_label')}
                                />

                                {
                                    (mlpackageName && mlpackageId) ? null : (
                                        <div>
                                            <Label value={t('mlpkg_create_pkgDesc_label')} />
                                            <Field
                                                name="packageDescription"
                                                type="text"
                                                component={AccessibleFormikInput}
                                                className="min-width-override"
                                                variant="outlined"
                                                color="secondary"
                                                placeholder={t('mlpkg_create_pkgDesc_ph')}
                                                aria-label={`${t('mlpkg_create_pkgDesc_ph')} text field`}
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                                multiline
                                                rows={6}
                                            />
                                        </div>)
                                }

                                {
                                    (mlpackageName && mlpackageId) ? null : (
                                        <div>
                                            <TooltipContainer
                                                title={t('tooltip_ml_package_input_type')}
                                                customStyle={{ marginTop: '20px' }}>
                                                <Label value={t('mlpkg_create_inputType_label')} />
                                            </TooltipContainer>
                                            <Field
                                                component={AccessibleFormikInput}
                                                className="textFeild min-width-override"
                                                select
                                                type="text"
                                                name="inputType"
                                                variant="outlined"
                                                color="secondary"
                                                disabled={(mlpackageName && mlpackageId) ? true : false || isSubmitting}
                                            >
                                                <MenuItem
                                                    value=""
                                                    disabled>
                                                    {' '}
                                                    {t('mlpkg_create_inputType_label')}
                                                    {' '}
                                                </MenuItem>
                                                {inputTypes.map((inputType) => (
                                                    <MenuItem
                                                        key={uuid()}
                                                        value={inputType.type}>
                                                        {inputType.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </div>
                                    )
                                }

                                <Label value={t('mlpkg_create_inputDesc_label')} />
                                <Field
                                    name="inputDescription"
                                    type="text"
                                    component={AccessibleFormikInput}
                                    className="min-width-override"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder={t('mlpkg_create_inputDesc_ph')}
                                    aria-label={`${t('mlpkg_create_inputDesc_ph')} text field`}
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    multiline
                                    rows={6}
                                />

                                <Label value={t('mlpkg_create_outputDesc_label')} />
                                <Field
                                    name="outputDescription"
                                    type="text"
                                    component={AccessibleFormikInput}
                                    className="min-width-override"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder={t('mlpkg_create_outputDesc_ph')}
                                    aria-label={`${t('mlpkg_create_outputDesc_ph')} text field`}
                                    autoComplete="off"
                                    disabled={isSubmitting}
                                    multiline
                                    rows={6}
                                />

                                {language &&
                                    <>
                                        <TooltipContainer
                                            title={t('tooltip_ml_package_language')}
                                            customStyle={{ marginTop: '20px' }}>
                                            <Label
                                                id="language-label"
                                                value={t('mlpkg_create_language_label')}
                                                required />
                                        </TooltipContainer>
                                        <Field
                                            component={AccessibleFormikInput}
                                            className="textFeild min-width-override"
                                            requiredField
                                            select
                                            tabIndex={-1}
                                            type="text"
                                            name="language"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="language"
                                            aria-describedby="language-label"
                                            aria-labelledby="language-label"
                                            disabled={isSubmitting}
                                        >
                                            <MenuItem
                                                value=""
                                                disabled>
                                                {' '}
                                                {t('mlpkg_create_language_label')}
                                                {' '}
                                            </MenuItem>
                                            {mlpackageLanguages?.
                                                filter(c => (blacklistedLanguages.indexOf(c.mlPackageLanguage || '') === -1)).
                                                map((inputType) => (
                                                    <MenuItem
                                                        data-testid={`language-${inputType.mlPackageLanguage}`}
                                                        key={uuid()}
                                                        value={inputType.mlPackageLanguage}>
                                                        {inputType.displayName}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                    </>}

                                {isPublic && languageDefaultVersion &&
                                    <>
                                        <Label
                                            value={t('mlpkg_create_language_version_label')}
                                            required />
                                        <Field
                                            component={AccessibleFormikInput}
                                            requiredField
                                            className="textFeild min-width-override"
                                            select
                                            type="text"
                                            name="languageVersion"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="languageversion"
                                            disabled={mlPackageLanguageVersionDisabled || isSubmitting}
                                        >
                                            <MenuItem
                                                value=""
                                                disabled>
                                                {' '}
                                                Language version
                                                {' '}
                                            </MenuItem>
                                            {mlpackageLanguageVersions.map((inputType) => (
                                                <MenuItem
                                                    data-testid={`languageversion-${inputType.version}`}
                                                    key={uuid()}
                                                    value={inputType.version}>
                                                    {inputType.version}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </>}

                                {
                                    mlpackageName && mlpackageId &&
                                    <>
                                        <Label value={t('mlpkg_create_changeLog_label')} />
                                        <Field
                                            name="changeLog"
                                            className="min-width-override"
                                            type="text"
                                            component={AccessibleFormikInput}
                                            variant="outlined"
                                            color="secondary"
                                            placeholder={t('mlpkg_create_enterChangeLog_label')}
                                            aria-label={`${t('mlpkg_create_enterChangeLog_label')} text field`}
                                            disabled={isSubmitting}
                                            autoComplete="off"
                                            multiline
                                            rows={6}
                                        />
                                    </>
                                }

                                {mlpackageId && !hasCustomPackageVersion ? '' :
                                    <TooltipContainer
                                        title={t('tooltip_ml_package_custom_pkg_version')}
                                        customStyle={{ marginTop: '20px' }}>
                                        <Label
                                            value={t('mlpackage_create_custom_package_version_label')}
                                            required={mlpackageId && hasCustomPackageVersion ? true : false} />
                                    </TooltipContainer>}

                                {!mlpackageId ? <div aria-label={`${t('mlpackage_create_custom_package_version_label')} switch ${values.isCustomVersionToggle ? 'checked' : 'unchecked'}`}>
                                    <Field
                                        name="isCustomVersionToggle"
                                        component={Switch}
                                        inputProps={{ 'aria-label': t('mlpackage_create_custom_package_version_label') }}
                                        color="secondary"
                                        disabled={isSubmitting}
                                        type="checkbox"
                                    />
                                </div> : ''}

                                {values.isCustomVersionToggle || hasCustomPackageVersion ?
                                    <>
                                        <Field
                                            component={AccessibleFormikInput}
                                            className="textFeild min-width-override"
                                            type="text"
                                            name="customVersion"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="customVersion"
                                            isValid={!errors['customVersion']}
                                            autoComplete="off"
                                            placeholder={t('mlpkg_create_enter_pkg_version_label')}
                                            aria-label={`${t('mlpkg_create_enter_pkg_version_label')} text field`}
                                            disabled={isSubmitting}
                                        />
                                    </> : ''}
                                <TooltipContainer
                                    title={t('tooltip_ml_package_enable_training')}
                                    customStyle={{ marginTop: '20px' }}>
                                    <Label value={t('mlpackage_retrainable_label')} />
                                </TooltipContainer>
                                <div aria-label={`${t('mlpackage_retrainable_label')} switch ${values.retrainable ? 'checked' : 'unchecked'}`}>
                                    <Field
                                        name="retrainable"
                                        component={Switch}
                                        color="secondary"
                                        type="checkbox"
                                        disabled={isSubmitting}
                                        inputProps={{ 'aria-label': t('mlpackage_retrainable_label') }}
                                    />
                                </div>
                                <TooltipContainer
                                    title={t('tooltip_ml_package_recommend_gpu')}
                                    customStyle={{ marginTop: '20px' }}>
                                    <Label value={t('mlpackage_create_enableGpu_label')} />
                                </TooltipContainer>
                                <div aria-label={`${t('mlpackage_create_enableGpu_label')} switch ${values.requireGpu ? 'checked' : 'unchecked'}`}>
                                    <Field
                                        name="requireGpu"
                                        component={Switch}
                                        color="secondary"
                                        disabled={isSubmitting}
                                        type="checkbox"
                                        inputProps={{ 'aria-label': t('mlpackage_create_enableGpu_label') }}
                                    />
                                </div>
                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </div >
    ) : null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    isPublic: state.config.isPublic,
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : false,
    authToken: state.auth.authToken,
    azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING,
}))(MLPackageCreate);
